
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { InboxMixin } from '@/components/inbox/InboxMixin';

@Component({
  name: 'filter-settings',
  components: {},
})
export default class FilterSettings extends mixins(InboxMixin) {
  opened = false;
  search = '';
  filterSelectedPlatform: string | null = null;
  filterSelectedWithErrors = false;
  searchTimeout?: number;
  filterPlatforms: string[] = [];
  interval?: number;

  async mounted() {
    // await this.updateProjectPlatforms();
  }

  async updateProjectPlatforms() {
    // if (this.project.id) {
    //   // const response = await Api.getProjectPlatforms(this.project.id);
    //   //
    //   // this.filterPlatforms = response.data;
    // }
  }

  @Watch('project')
  async onProjectChange() {
    await this.updateProjectPlatforms();
  }

  getFilter() {
    return {
      projectId: this.bot?.id,
      withErrors: this.filterSelectedWithErrors,
      platform: this.filterSelectedPlatform,
    };
  }

  open() {
    if (this.opened) {
      this.close();
      return;
    }
    this.opened = true;
    document.body.addEventListener('click', this.onClick);
  }
  close() {
    this.opened = false;
    document.body.removeEventListener('click', this.onClick);
  }
  onClick(event: MouseEvent) {
    if (
      event.target &&
      (this.$refs.button as HTMLButtonElement | undefined)?.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (!this.opened || !event.target || !this.$refs.popover) return;
    if (!(this.$refs.popover as HTMLElement).contains(event.target as HTMLElement)) {
      this.close();
    }
  }
  async handleFilter() {
    this.opened = false;
    this.$emit('loadConversations', {
      projectId: this.bot?.id,
      withErrors: this.filterSelectedWithErrors,
      platform: this.filterSelectedPlatform,
    });
  }

  @Watch('filterSelectedWithErrors')
  async watchFilterSelectedWithErrors() {
    this.$emit('loadConversations', {
      projectId: this.bot?.id,
      withErrors: this.filterSelectedWithErrors,
      platform: this.filterSelectedPlatform,
    });
  }

  async handleFilterSelectedWithErrors() {
    this.filterSelectedWithErrors = !this.filterSelectedWithErrors;
    await this.handleFilter();
  }

  async handleFilterSelectedPlatform(platform: string) {
    if (this.filterSelectedPlatform && this.filterSelectedPlatform === platform) {
      this.filterSelectedPlatform = null;
    } else {
      this.filterSelectedPlatform = platform;
    }
    await this.handleFilter();
  }

  async toggleLiveMode() {
    const val = !this.isLiveMode;
    await this.$store.dispatch('PreferencesModule/updateLiveMode', val);
  }

  async handleSearch() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = window.setTimeout(async () => {
      
      await this.$store.dispatch('inbox/clearConversations');
      this.$emit('updateSearchLoading', true);

      await this.$store.dispatch('inbox/fetchConversations', {
        projectId: this.bot?.id,
        search: this.search,
      });
      this.$emit('updateSearchLoading', false);
    }, 500);
  }

  @Watch('isLiveMode')
  async watchLiveMode() {
    if (this.isLiveMode) {
      this.interval = window.setInterval(async () => {
        this.$emit('loadConversations', {
          projectId: this.bot?.id,
          withErrors: this.filterSelectedWithErrors,
          platform: this.filterSelectedPlatform,
        });
      }, 1000);
    } else {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  }
}
